import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

// Components
import Pagination from '../components/blog/pagination';

// data
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

// utils
import withTranslate from '../utils/withTransWrapper';
import Footer from '../components/layout/footer';
import SEO from '../components/seo';
import moment from 'moment';
import { DATE_FORMAT } from '../styles/utils/typography';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.uiColorBG};
`;

const PictureStyle = styled(Img)`
  height: 480px !important;
  object-fit: cover !important;
  object-position: center !important;
  margin: 56px 0;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    height: 430px !important;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    height: 230px !important;
  }
`;

const PictureDetailStyle = styled.img`
  width: 1000px;
  height: 480px !important;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    height: 430px !important;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    height: 230px !important;
  }
`;

const DetailPage = styled.div`
  color: ${({ theme }) => theme.color.textBlack};
  max-width: 1400px;
  margin: 160px 5.5em 0 5.5em;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    margin: 160px 0 0 0;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    margin: 160px 1.5em 0 1.5em;
  }
`;

const TitleDetailPage = styled.div`
  ${({ theme }) => theme.typography.titleFootage()};
  h1 {
    font-size: 36px;
    font-weight: 700;
  }
  text-align: center;
  margin: 0 0 27px 0;

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.content3dBig()};
    h1 {
      font-size: 24px;
      font-weight: 700;
    }
  }
`;

const DateText = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.textMenu};
  text-align: center;
`;

const DescriptionDetailPage = styled.div`
  ${({ theme }) => theme.typography.descBlogMiddle()};
  margin: 15px 0 30px 0;

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    ${({ theme }) => theme.typography.footageDetail()};
  }
  a {
    color: ${({ theme }) => theme.color.textRed};
  }
`;

const BlogBody = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.device.laptop}) {
    padding: 0 55px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    padding: 0;
  }
`;

const PictureLayout = styled.div`
  display: flex;
  justify-content: center;
  margin: 41px 0;
`;

const Hr = styled.hr`
  background: ${({ theme }) => theme.color.uiColorGray};
  margin-top: 15px;
`;

const BottomPage = styled.div``;

const BlogPost = ({ data, pageContext }) => {
  const prismicBlogPosts = data.prismicItBlogPost;

  if (!prismicBlogPosts) return null;
  const documentPost = prismicBlogPosts;
  const DataBlogPost = {
    image_banner: documentPost.data.blog_hero_image.fluid,
    title: documentPost.data.blog_title.raw,
    description_first: documentPost.data.blog_description_first_path.raw,
    description_second: documentPost.data.blog_description_second_path.raw,
    image_detail: documentPost.data.blog_image.fluid,
    date: documentPost.data.blog_post_date,
  };

  const next = pageContext.next
    ? {
        url: `/blog/${pageContext.next.uid}`,
        title: pageContext.next.data.blog_title.raw,
        date: pageContext.next.data.blog_post_date,
        image: pageContext.next.data.blog_hero_image?.fluid?.src,
      }
    : null;
  const prev = pageContext.prev
    ? {
        url: `/blog/${pageContext.prev.uid}`,
        title: pageContext.prev.data.blog_title.raw,
        date: pageContext.prev.data.blog_post_date,
        image: pageContext.prev.data.blog_hero_image?.fluid?.src,
      }
    : null;

  return (
    <>
      <SEO title={RichText.asText(DataBlogPost.title)} />
      <Wrapper>
        <BlogBody>
          <DetailPage>
            <TitleDetailPage>{RichText.render(DataBlogPost.title)}</TitleDetailPage>
            <DateText>{moment(DataBlogPost.date).format(DATE_FORMAT)}</DateText>
            <PictureStyle
              fluid={DataBlogPost.image_banner}
              loading="eager"
              alt={DataBlogPost.title}
            />
            <DescriptionDetailPage>
              <RichText
                render={DataBlogPost.description_first}
                elements={{
                  hyperlink: function a({ children, data }) {
                    return (
                      <a href={data.url} rel="nofollow" target="blank">
                        {children}
                      </a>
                    );
                  },
                }}
              />
            </DescriptionDetailPage>
            {DataBlogPost.image_detail && (
              <PictureLayout>
                <PictureDetailStyle src={DataBlogPost.image_detail?.src} loading="eager" />
              </PictureLayout>
            )}
            <DescriptionDetailPage>
              <RichText
                render={DataBlogPost.description_second}
                elements={{
                  hyperlink: function a({ children, data }) {
                    return (
                      <a href={data.url} rel="nofollow" target="blank">
                        {children}
                      </a>
                    );
                  },
                }}
              />
            </DescriptionDetailPage>
            <BottomPage>
              <Hr />
              <Pagination prev={prev} next={next} />
            </BottomPage>
          </DetailPage>
        </BlogBody>
        <Footer />
      </Wrapper>
    </>
  );
};

export default withTranslate(BlogPost);

export const query = graphql`
  query($uid: String) {
    prismicItBlogPost(uid: { eq: $uid }) {
      tags
      uid
      type
      data {
        blog_hero_image {
          fluid(maxWidth: 2048) {
            src
          }
        }
        blog_image {
          fluid {
            src
          }
        }
        blog_post_date(formatString: "MM/DD/YYYY")
        blog_title {
          raw
        }
        blog_description_first_path {
          raw
        }
        blog_description_second_path {
          raw
        }
      }
    }
    prismicBlogPage {
      type
      id
      data {
        blog_page_title {
          raw
        }
      }
    }
  }
`;
