import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { ButtonText } from '../button';

import { RichText } from 'prismic-reactjs';
import { DATE_FORMAT } from '../../styles/utils/typography';
import RightArrowIcon from '../../images/RightArrow.svg';
import LeftArrowIcon from '../../images/LeftArrow.svg';

const PageControl = styled.div`
  height: auto;
  justify-content: space-between;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 15px 0 166px 0;
  align-content: center;

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    flex-flow: column wrap;
  }
`;
const ContentDetail = styled.div`
  display: flex;
`;
const Detail = styled.div`
  max-width: 302px;
  overflow: hidden;
`;
const SubTitleDetail = styled.div`
  height: 51px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  h5 {
    line-height: 1.3 !important;
  }
`;

const Date = styled.div`
  padding: 15px 0;
  h5 {
    color: ${({ theme }) => theme.color.textMenu} !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }
`;

const ImageStyle = styled.img`
  width: 88px;
  height: 88px;
  object-fit: cover;
`;

const Prev = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  h5 {
    color: ${({ theme }) => theme.color.textBlack};
    font-size: 18px;
    font-weight: 600;
    margin: 0 40px 0 24px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 100%;
  }
`;

const Next = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  h5 {
    color: ${({ theme }) => theme.color.textBlack};
    font-size: 18px;
    font-weight: 600;
    margin: 0 24px 0 40px;
    text-align: end;
  }

  @media (max-width: ${({ theme }) => theme.device.mobileL}) {
    width: 100%;
    margin-top: 20px;
  }
`;

const RightArrowWrapper = styled.div`
  margin-bottom: 21px;
  display: flex;
  align-items: center;
`;

const LeftArrowWrapper = styled.div`
  margin-bottom: 21px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ArrowText = styled.p`
  margin: 0 7.5px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.textRed};
`;

const IconWrapper = styled.img`
  width: 13px;
  height: 10px;
`;

const Pagination = ({ prev, next }) => {
  return (
    <PageControl>
      <>
        <Prev>
          {next !== null ? (
            <ButtonText to={next.url}>
              <RightArrowWrapper>
                <IconWrapper src={LeftArrowIcon} alt="Previous blog" />
                <ArrowText>Vorheriges Blog</ArrowText>
              </RightArrowWrapper>
              <ContentDetail>
                <ImageStyle src={next.image} alt="Previous blog image" />
                <Detail>
                  <SubTitleDetail>
                    <h5
                      css={`
                        line-height: 1.3 !important;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                      `}
                    >
                      {RichText.asText(next.title)}
                    </h5>
                  </SubTitleDetail>
                  <Date>
                    <h5>{moment(next.date).format(DATE_FORMAT)}</h5>
                  </Date>
                </Detail>
              </ContentDetail>
            </ButtonText>
          ) : null}
        </Prev>
      </>
      <>
        <Next>
          {prev !== null ? (
            <ButtonText to={prev.url}>
              <LeftArrowWrapper>
                <ArrowText>Nächstes Blog</ArrowText>
                <IconWrapper src={RightArrowIcon} alt="Next blog" />
              </LeftArrowWrapper>
              <ContentDetail>
                <Detail>
                  <SubTitleDetail>
                    <h5
                      css={`
                        line-height: 1.3 !important;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                      `}
                    >
                      {RichText.asText(prev.title)}
                    </h5>
                  </SubTitleDetail>
                  <Date>
                    <h5>{moment(prev.date).format(DATE_FORMAT)}</h5>
                  </Date>
                </Detail>
                <ImageStyle src={prev.image} alt="Next blog image" />
              </ContentDetail>
            </ButtonText>
          ) : null}
        </Next>
      </>
    </PageControl>
  );
};

Pagination.propTypes = {
  prev: PropTypes.object,
  next: PropTypes.object,
};
export default Pagination;
